import gql from "graphql-tag";

export const GET_STAKED_LIST = (user: string) => {
  return gql`
  query StakeEntity {
  stakeEntities(where:{user:"${user.toLowerCase()}"}) {
    id
    stakeTime
    holdTimeOption
    amount
    user
  }
}
`;
};
