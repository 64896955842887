import styled from "styled-components";

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.turquoise},
    ${({ theme }) => theme.colors.blue}
  );
  margin: 0 auto;
  text-align: center;
  font-family: "Helvetica Neue";
  font-weight: 300;
  padding: 10px 20px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 45px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0;
  padding: 10px;
  line-height: 30px;
  vertical-align: top;
  font-weight: 300;
  font-size: 1.25rem;
  & > p {
    margin: 0;
  }
  & > div > a {
    margin: 10px;
  }
  & > a {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
    padding: 0px 8px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.875rem;
    margin-left 10px;
  }
  @media all and (max-width: 600px) {
    display: flex;
    align-items: center;
    text-align: center;
    & > a {
      margin-top:10px;
      margin-left 0;
    }
  }
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  margin: 10px 10px;
  background: ${({ theme }) => theme.colors.white};
  @media all and (max-width: 600px) {
    height: 1px;
    width: 100px;
  }
`;

export { Wrapper, Title, MainBlock, ContactContainer, Divider };
