import React from "react";
import { Label, Input, InputContainer, InputLabel } from "./styles";
import Token from "../../assets/images/token.svg";

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

export enum EndIconType {
  DSCP = "DSCP",
  DSCPL = "DSCPL",
}

export const CustomInput = React.memo(function InnerInput({
  value,
  placeholder,
  endIcon,
  onValueChange,
  label,
  ...rest
}: {
  value: string | number;
  endIcon: EndIconType;
  label: string;
  onValueChange?: (value: string) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  const escapeRegExp = (string: string): string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };
  const enforcer = (nextUserInput: string) => {
    if (!onValueChange) return;
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onValueChange(nextUserInput);
    }
    if (nextUserInput === ".") {
      onValueChange("0.");
    }
  };

  return (
    <>
      <InputLabel> {label}</InputLabel>
      <InputContainer>
        <Input
          value={value}
          onChange={(event) => {
            // replace commas with periods, because uniswap exclusively uses period as the decimal separator
            enforcer(event.target.value.replace(/,/g, "."));
          }}
          // universal input options
          inputMode="decimal"
          title="Token Amount"
          autoComplete="off"
          autoCorrect="off"
          // text-specific options
          type="text"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder={placeholder || "0.0"}
          minLength={1}
          maxLength={79}
          spellCheck="false"
          {...rest}
        />
        <div style={{ display: "flex" }}>
          {endIcon !== EndIconType.DSCP && <img src={Token} alt="token" />}
          <Label>
            {endIcon === EndIconType.DSCP
              ? EndIconType.DSCP
              : EndIconType.DSCPL}
          </Label>
        </div>
      </InputContainer>
    </>
  );
});
