import { useState } from "react";
import { getDateDiff, getFormattedDate } from "../../utils/dateFormatter";
import {
  Grid,
  GridRow,
  GridHeader,
  Divider,
  ExpandButton,
  ClaimButton,
  ClaimText,
  CenteredContent,
} from "./styles";
import { useWeb3React } from "@web3-react/core";

import "moment/locale/zh-cn";
import { BSC_CHAIN_ID, getDisciplinaContract } from "../../hooks/useContract";
import { useTranslation } from "react-i18next";
import { holdTimeDays } from "../Swap";
import "moment/locale/zh-cn";
import Big from "big.js";
import { createTransaction, Transaction, useStore } from "../../services/store";
import { Modals } from "../../components/modals";

const ROWS_PER_PAGE = 10;
const SECOND_IN_DAY = 86400000;

export interface RowInterface {
  id: number;
  amount: string;
  holdTimeOption: string;
  holdTimeDays: string;
  stakeTime: number;
  user: string;
  isWithdrawn?: boolean;
  withdrawTime?: number;
}

export default function Withdraw({
  list,
  setList,
}: {
  list: RowInterface[];
  setList: React.Dispatch<React.SetStateAction<RowInterface[]>>;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { account, library, chainId } = useWeb3React();
  const { t } = useTranslation();
  const { updateTransactions, amountDSCPL } = useStore();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(false);
  };

  const switchState = () => {
    setIsExpanded(!isExpanded);
  };

  const sorted = list
    .map((el) => {
      return {
        ...el,
        withdrawTime:
          el.stakeTime +
          holdTimeDays[Number(el.holdTimeOption)] * SECOND_IN_DAY,
      };
    })
    .sort((a, b) => Number(a.withdrawTime) - Number(b.withdrawTime));

  const renderList = (arr: RowInterface[], isExpanded: boolean) => {
    let newArray: RowInterface[] = arr;
    if (!isExpanded) {
      newArray = arr.slice(0, ROWS_PER_PAGE);
    }

    return newArray.map((el, index) => (
      <div key={index} style={{ width: "100%" }}>
        <Row {...el} />
        {index !== newArray.length - 1 && <Divider />}
      </div>
    ));
  };

  const claim = async (id: number, amountValue: Big) => {
    if (!library) return;
    const signer = library.getSigner();
    const contract = getDisciplinaContract(signer);
    if (contract && chainId === BSC_CHAIN_ID) {
      const tx = await contract.withdraw(id);
      const transaction: Transaction = createTransaction(
        tx.hash,
        "eth",
        t("transaction_withdraw", { amount: amountValue.toFixed() }),
        t("transaction_withdraw_success", { amount: amountValue.toFixed() }),
        t("transaction_withdraw_failure", { amount: amountValue.toFixed() }),
        chainId
      );
      updateTransactions(transaction);
      await tx.wait();
      const newList = await Promise.all(
        list.map(async (el) => {
          const response = await contract.swapInfo(el.id);
          return {
            ...el,
            isWithdrawn: response.withdrawn,
          };
        })
      );

      setList([...newList.filter((el) => !el.isWithdrawn)]);
    }
  };

  const Row = ({
    id,
    amount,
    stakeTime,
    withdrawTime = Date.now(),
  }: RowInterface) => {
    const daysToWithdraw = getDateDiff(withdrawTime, t);

    return (
      <GridRow>
        <div>
          {amount} {t("common_dscpl")}
        </div>
        <div>{getFormattedDate(Number(stakeTime))}</div>

        <div>
          {Date.now() <= withdrawTime ? (
            <ClaimText>{daysToWithdraw}</ClaimText>
          ) : (
            <ClaimButton
              onClick={() =>
                new Big(amount).gt(amountDSCPL)
                  ? setOpen(true)
                  : claim(id, new Big(amount))
              }
            >
              {t("withdraw_claim")}
            </ClaimButton>
          )}
        </div>
      </GridRow>
    );
  };

  if (!account || chainId !== BSC_CHAIN_ID) {
    return (
      <CenteredContent>
        {!account ? (
          <h3>{t("withdraw_connect_metamask")}</h3>
        ) : (
          <h3>{t("withdraw_connect_binance")}</h3>
        )}
      </CenteredContent>
    );
  }

  return (
    <Grid>
      {sorted.length > 0 ? (
        <>
          <GridHeader>
            <div>{t("withdraw_amount")}</div>
            <div>{t("withdraw_staking_start")}</div>
          </GridHeader>
          {renderList(sorted, isExpanded)}
          {sorted.length > 10 && (
            <ExpandButton onClick={switchState}>
              {!isExpanded ? t("withdraw_see_all") : t("withdraw_hide")}
            </ExpandButton>
          )}
        </>
      ) : (
        <CenteredContent>
          <h3>{t("withdraw_no_dscpl")}</h3>
        </CenteredContent>
      )}
      <Modals open={open} close={handleClickOpen} />
    </Grid>
  );
}
