import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { client } from "../src/apollo/client";
import "./services/i18n";
import { ThemeProvider } from "styled-components";
import { myTheme } from "./style/Global";
import Contact from "./views/Contacts";
import Overview from "./views/Overview";
import { useTranslation } from "react-i18next";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEagerConnect, useInactiveListener } from "./hooks/useProvider";
import { ApolloProvider } from "react-apollo";
import { RefreshContextProvider } from "./services/RefreshService";
import TransactionUpdater from "./services/TransactionUpdater";
import BlockUpdater from "./services/BlockUpdater";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notify = (text: string) => toast.dark(text);
//INFO: delete 'dark' for default styles

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

export default function AppWrapper() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  );
}

function App() {
  const { i18n } = useTranslation();
  const context = useWeb3React<Web3Provider>();
  const { connector } = context;

  useEffect(() => {
    const currentLanguage = localStorage.getItem("lang") ?? "en";
    i18n.changeLanguage(currentLanguage);
  }, [i18n]);

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState<any>();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <ApolloProvider client={client}>
      <RefreshContextProvider>
        <Router>
          <TransactionUpdater />
          <BlockUpdater />
          <ThemeProvider theme={myTheme}>
            <Overview />
            <Contact />
          </ThemeProvider>
        </Router>
      </RefreshContextProvider>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        autoClose={5000}
        pauseOnHover
      />
    </ApolloProvider>
  );
}
