import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient, DefaultOptions } from "apollo-client";
import { HttpLink } from "apollo-link-http";

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: new HttpLink({
    uri:
      "https://api.thegraph.com/subgraphs/name/gastonovich/disciplina-subgraph",
  }),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
