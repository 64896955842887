import close_icon from "../../assets/images/close_icon.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Transaction } from "../../services/store";
import { transformAddress } from "../../utils/numberFormatter";
import copy_icon from "../../assets/images/copy_icon.svg";
import { toast } from "react-toastify";

const Wrap = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  z-index: 17;
  min-width: 340px;
  min-height: 300px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  border: none !important;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: auto;
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }
`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.modalPendingBg};
  position: fixed;
  top: 0px;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  margin: 0.5rem 1.5rem;
  margin-right: auto;
`;

const TextTransaction = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  margin: 0.5rem 1.5rem;
  margin-right: auto;
`;

const IconButton = styled.button`
  border: none;
  background: transparent;
  margin: 0.5rem 1rem;
  outline: none;
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }
`;

type ModalProps = {
  close: () => void;
  open: boolean;
  transactions: Transaction[];
};

export const TransactionModal = ({ close, open, transactions }: ModalProps) => {
  const { t } = useTranslation();
  const notifyAddress = () => toast.dark(t("button_copy"));
  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
  };
  const pendingTransactions = transactions.filter(
    (el) => el.status === "pending"
  );

  if (!open || pendingTransactions.length === 0) {
    return null;
  }

  return (
    <ModalBackground onClick={close}>
      <Wrap
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box>
          <Button type="button" onClick={(e) => close()}>
            <img src={close_icon} alt="close_icon" />
          </Button>
          <TextContainer>{t("withdraw_pending_transaction")} </TextContainer>
          {pendingTransactions.length > 0 && (
            <TextTransaction>
              {pendingTransactions.map((el, index) => {
                return (
                  <Row
                    key={index}
                    onClick={() => {
                      copyToClipboard(el.hash);
                      notifyAddress();
                    }}
                  >
                    <span>{transformAddress(el.hash)}</span>
                    <IconButton>
                      <img src={copy_icon} alt="copy_icon" />
                    </IconButton>
                  </Row>
                );
              })}
            </TextTransaction>
          )}
        </Box>
      </Wrap>
    </ModalBackground>
  );
};
