import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
  colors: {
    white: "#fff",
    black: "#000",
    blue: "#002590",
    turquoise: "#05CEC7",
    backgroundWhite: "#F2F2F2",
    buttonBorder: "#00E7BF",
    buttonActiveText: "#203F5C",
    buttonDisabled: "#B5B5B5",
    darkBlueText: "003294",
    blackText: "#242424",
    greenCheckPoint: "#00E5AE",
    blueCheckPoint: "#002C93",
    blueModal: "#003695",
    buttonBackground: "#00E6BE",
    buttonBackgroundHover: "#63f7de",
    defaultYellow: "#F6B60A",
    modalBg: "rgb(88 88 88 / 60%)",
    sliderGradient: "linear-gradient(90deg, #f5c404 0%, #ff0155 100.01%)",
    modalPendingBg: "rgb(56 78 115 / 40%);",
  },
};

export { myTheme };
