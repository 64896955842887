import moment from "moment";
import { TFunction } from "react-i18next";

const MILLISECONDS_IN_SECONDS = 1000;

export const toSeconds = (milliseconds: number) => {
  return Math.floor(milliseconds / MILLISECONDS_IN_SECONDS);
};
export const toMilliseconds = (seconds: number | string) => {
  return Number(seconds) * MILLISECONDS_IN_SECONDS;
};

export function getFormattedDate(date: Date | number): string {
  return moment(date).format("DD.MM.YYYY");
}

export function getDateDiff(
  stakeTime: number,
  t: TFunction<"translation">
): string | undefined {
  const now = moment(new Date());
  const end = moment(stakeTime);

  const timeDifferenceDays = end.diff(now, "days");
  const timeDifferenceHours = end.diff(now, "hours");
  const timeDifferenceMinutes = end.diff(now, "minutes");

  if (timeDifferenceDays > 0) {
    return timeDifferenceDays === 1
      ? `${timeDifferenceDays} ${t("withdraw_day")}`
      : `${timeDifferenceDays} ${t("withdraw_days")}`;
  }
  if (timeDifferenceHours > 0) {
    return timeDifferenceHours === 1
      ? `${timeDifferenceHours} ${t("withdraw_hour")}`
      : `${timeDifferenceHours} ${t("withdraw_hours")}`;
  }
  if (timeDifferenceMinutes > 0) {
    return timeDifferenceMinutes === 1
      ? `${timeDifferenceMinutes} ${t("withdraw_minute")}`
      : `${timeDifferenceMinutes} ${t("withdraw_minutes")}`;
  }
}
