const addressEth = {
  bridge: {
    3: "0x3448d4A6C49eFbad4a197bd4715818ee4c0230BC",
    1: "0x008d9baff91c6b10301f27edb10bd21426efb082",
  },
  dscp: {
    3: "0x097D4D6914C7A82B70aa7aC0BdDD27f31CdE2aA2",
    1: "0x03e3f0c25965f13dbbc58246738c183e27b26a56",
  },
};
const addressBsc = {
  dscpl: {
    97: "0xFc81003D77E9906f9DbabA868A2679A962B01516",
    56: "0xdECE0F6864c1511369ae2c30B90Db9f5fe92832c",
  },
  disciplina: {
    97: "0x36AE9a4d8243859A13A909A3D642aA86A5A185B6",
    56: "0x008d9baff91c6b10301f27edb10bd21426efb082",
  },
};

export { addressEth, addressBsc };
