import React, { Dispatch, SetStateAction, useState } from "react";
export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
}
export interface Transaction {
  status: TransactionStatus;
  hash: string;
  summary: string;
  chainId: number;
  chain: TransactionChain;
  addedTime: number;
  lastCheckedBlockNumber?: number;
  receipt?: SerializableTransactionReceipt;
  onSuccess?: string;
  onFailure?: string;
}
export const TRANSACTION_LOCALSTORAGE_KEY = "TRANSACTIONS";
const transactionsInitialState =
  (JSON.parse(
    localStorage.getItem(TRANSACTION_LOCALSTORAGE_KEY) as string
  ) as Transaction[]) || [];

export type TransactionStatus = "success" | "canceled" | "pending" | "failed";
export type TransactionChain = "eth";
export interface BlockInfo {
  data: {
    [key in TransactionChain]: { chainId: number; blockNumber: number }[];
  };
}

export interface StoreProps {
  transactions: Transaction[];
  setTransactions: Dispatch<SetStateAction<Transaction[]>>;
  blockNumberInfo: BlockInfo;
  setBlockNumberInfo: Dispatch<SetStateAction<BlockInfo>>;
  updateTransactions: (transaction: Transaction) => void;
  amountDSCPL: string;
  setAmountDSCPL: Dispatch<SetStateAction<string>>;
}
export function createCtx<ContextType>() {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

export function createTransaction(
  hash: string,
  chain: TransactionChain,
  summary: string,
  onSuccess: string,
  onFailure: string,
  chainId = 1
): Transaction {
  return {
    hash: hash,
    status: "pending",
    chainId: chainId,
    chain: chain,
    addedTime: new Date().getTime(),
    summary: summary,
    onSuccess: onSuccess,
    onFailure: onFailure,
  };
}

export const [useStore, CtxProvider] = createCtx<StoreProps>();

export const StoreProvider = ({ children }: { children: JSX.Element }) => {
  const [transactions, setTransactions] = useState<Transaction[]>(
    transactionsInitialState
  );
  const [blockNumberInfo, setBlockNumberInfo] = useState<BlockInfo>({
    data: { eth: [] },
  });
  const [amountDSCPL, setAmountDSCPL] = useState("");

  const updateTransactions = (transaction: Transaction) => {
    let newState: Transaction[];
    const index = transactions.findIndex((el) => el.hash === transaction.hash);
    if (index >= 0) {
      newState = [...transactions];
      newState[index] = transaction;
    } else {
      newState = [...transactions, transaction];
    }
    setTransactions(newState);
    localStorage.setItem(
      TRANSACTION_LOCALSTORAGE_KEY,
      JSON.stringify(newState)
    );
  };

  return (
    <CtxProvider
      value={{
        transactions,
        setTransactions,
        blockNumberInfo,
        setBlockNumberInfo,
        updateTransactions,
        amountDSCPL,
        setAmountDSCPL,
      }}
    >
      {children}
    </CtxProvider>
  );
};
