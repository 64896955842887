import React, { useState } from "react";
import { HeaderBlock, Select, SelectRoot } from "./styles";
import Logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import PendingButton from "../../components/pending_button";
import { Transaction, useStore } from "../../services/store";
import { TransactionModal } from "../../components/modal_transaction";

export default function Header() {
  const { i18n } = useTranslation();
  const { transactions } = useStore();

  const currentLanguage = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const transactionCount = transactions.filter(
    (item: Transaction) => item.status === "pending"
  ).length;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderBlock>
        <img src={Logo} alt="logo" />
        <nav>
          {/* <NavLinks>
          <a href="#about">About</a>
          <Divider />
          <a href="#contacts">Contacts</a>
          <Divider />
        </NavLinks> */}
          {transactionCount !== 0 && (
            <PendingButton
              isActive={true}
              pendingCount={transactionCount}
              onClick={() => setOpen(true)}
            />
          )}

          <Select className="select">
            <SelectRoot
              name="slct"
              id="slct"
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="en" selected={currentLanguage === "en"}>
                EN
              </option>
              <option value="zh" selected={currentLanguage === "zh"}>
                ZH
              </option>
            </SelectRoot>
          </Select>
        </nav>
      </HeaderBlock>
      <TransactionModal
        close={handleClickOpen}
        open={open}
        transactions={transactions}
      />
    </>
  );
}
