import styled from "styled-components";

const Grid = styled.div`
  min-height: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GridHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  > div {
    flex: 5;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 16px;
  }
`;
const GridRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  margin: 19px 0;
  & > div {
    &:first-child {
      flex: 4;
      color: ${({ theme }) => theme.colors.buttonBackground};
      font-style: normal;
      font-weight: 100;
      font-size: 1.125rem;
      line-height: 21px;
    }
    &:nth-child(2) {
      flex: 2;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 16px;
    }
    &:last-child {
      text-align: end;
      flex: 2;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ExpandButton = styled.div`
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.colors.buttonBackground};
  padding: 5px 50px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 21px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }
  @media all and (max-width: 500px) {
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const ClaimButton = styled.div`
  text-align: center;
  outline: none;
  font-family: Roboto;
  padding: 4px 0px;
  background: ${({ theme }) => theme.colors.buttonBackground};
  border: 2px solid ${({ theme }) => theme.colors.buttonBackground};
  color: ${({ theme }) => theme.colors.buttonActiveText};
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 21px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const ClaimText = styled.h1`
  text-align: center;
  outline: none;
  font-family: Roboto;
  font-size: 1rem;
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 580px;
`;
export {
  Grid,
  GridHeader,
  GridRow,
  Divider,
  ExpandButton,
  ClaimButton,
  ClaimText,
  CenteredContent,
};
