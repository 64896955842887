import { useWeb3React } from "@web3-react/core";
import React from "react";
import styled from "styled-components";
import { ETH_CHAIN_ID } from "../../hooks/useContract";
import { Web3Provider } from "@ethersproject/providers";
import { injected } from "../../hooks/useProvider";
import { useTranslation } from "react-i18next";
import Big from "big.js";

const Button = styled.button`
  color: ${({ theme }) => theme.colors.buttonActiveText};
  border: none;
  padding: 31px 27px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 21px;
  background-color: ${({ theme }) => theme.colors.buttonBackground};
  border-radius: 10px;
  z-index: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 620px;
  cursor: pointer;
  & > span {
    font-weight: bold;
    pointer-events: none;
  }
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.buttonDisabled};
  }

  @media all and (max-width: 500px) {
    margin: 20px 0;
    font-size: 1.5625rem;
    padding: 15px 10px;
    line-height: 25px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > button {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const SwapButtons = React.memo(function InnerInput({
  onSwap,
  onApprove,
  amount,
  allowance,
  maxAmount,
  ...rest
}: {
  onSwap: () => void;
  onApprove: () => void;
  amount: string;
  allowance: Big;
  maxAmount: Big;
} & Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "ref" | "onChange" | "as"
>) {
  const { account, activate, chainId } = useWeb3React<Web3Provider>();
  const { t } = useTranslation();
  const connect = () => {
    activate(injected);
  };

  const amountValue = new Big(amount !== "" ? amount : 0);
  const isDisabled = amountValue.gt(maxAmount);
  const isApproved = allowance.gte(amountValue);
  const isZero = amount === "" || amountValue.eq(0);
  if (!account || chainId !== ETH_CHAIN_ID)
    return (
      <>
        {!account ? (
          <Button onClick={connect}>{t("withdraw_connect_metamask")}</Button>
        ) : (
          <Button disabled>{t("withdraw_connect_ethereum")}</Button>
        )}
      </>
    );

  return (
    <ButtonContainer>
      <Button disabled={isApproved || isDisabled} {...rest} onClick={onApprove}>
        {t("withdraw_approve")}
      </Button>
      <Button disabled={!isApproved || isDisabled || isZero} onClick={onSwap}>
        {t("swap_button")}
      </Button>
    </ButtonContainer>
  );
});
