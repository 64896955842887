import { useState, useEffect } from "react";
import {
  Wrapper,
  ModalContainer,
  LeftPicture,
  RightPicture,
  InfoContainer,
  AboutBlockText,
  ComponentContainer,
  SwapHeader,
  Button,
  SwapAlert,
} from "./styles";
import { useTranslation } from "react-i18next";
import background_picture from "../../assets/images/background_picture.svg";
import Header from "../Header";
import Medium from "../../assets/images/medium.svg";
import Swap, { RECOMMENDED_INDEX } from "../Swap";
import { SwapButtons } from "../../components/button";
import {
  getEthAddress,
  getBridgeContract,
  getDSCPContract,
  ETH_CHAIN_ID,
  BSC_CHAIN_ID,
  getDisciplinaContract,
} from "../../hooks/useContract";
import { useProvider } from "../../hooks/useProvider";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { toSeconds } from "../../utils/dateFormatter";
import useRatio from "../../hooks/useRatio";
import { addressEth } from "../../config/addresses";
import {
  formatPercentage,
  fromBNValue,
  toBNValue,
} from "../../utils/numberFormatter";
import Withdraw, { RowInterface } from "../Withdraw";
import { GET_STAKED_LIST } from "../../apollo/queries";
import { client } from "../../apollo/client";
import { toMilliseconds } from "../../utils/dateFormatter";
import Big from "big.js";
import { createTransaction, Transaction, useStore } from "../../services/store";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { SWAP, WITHDRAW } from "../../config/routes";

export default function Overview() {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  const location = useLocation();
  let history = useHistory();
  const currentTab = location.pathname.toLowerCase();
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>("");
  const [holdValue, setHoldValue] = useState<number>(RECOMMENDED_INDEX);
  const { rateByMonth } = useRatio();
  const [options, setOptions] = useState<number[]>(rateByMonth);
  const provider = useProvider();
  const [list, setList] = useState<RowInterface[]>([]);
  const [allowance, setAllowance] = useState<Big>(new Big(0));
  const [maxAmount, setMaxAmount] = useState<Big>(new Big(0));
  const { updateTransactions } = useStore();

  useEffect(() => {
    const getAllowance = async (account: string) => {
      const contractDSCP = getDSCPContract(provider);

      const bridgeAddress = getEthAddress(addressEth.bridge);
      if (
        contractDSCP &&
        account &&
        bridgeAddress &&
        chainId === ETH_CHAIN_ID
      ) {
        const newAllowance = await contractDSCP.allowance(
          account,
          bridgeAddress
        );
        setAllowance(fromBNValue(newAllowance.toString()));
      }
    };
    if (account) {
      getAllowance(account);
    }
  }, [account, provider, chainId]);

  useEffect(() => {
    async function fetchList(account: string) {
      try {
        const response = await client.query({
          query: GET_STAKED_LIST(account),
        });
        const list: RowInterface[] = response.data.stakeEntities;
        if (chainId === BSC_CHAIN_ID) {
          const contract = getDisciplinaContract(provider);
          if (!contract) return;
          const newList = await Promise.all(
            list.map(async (el) => {
              const response = await contract.swapInfo(el.id);
              el.stakeTime = toMilliseconds(el.stakeTime);
              el.amount = fromBNValue(el.amount).toFixed();

              return {
                ...el,
                isWithdrawn: response.withdrawn,
              };
            })
          );

          setList([...newList.filter((el) => !el.isWithdrawn)]);
        } else {
          setList(
            list.map((el) => {
              el.stakeTime = toMilliseconds(el.stakeTime);
              el.amount = fromBNValue(el.amount).toFixed();
              return el;
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (account) {
      fetchList(account);
    }
  }, [account, chainId, provider]);

  useEffect(() => {
    const updateRatio = async () => {
      const contract = getBridgeContract(provider);

      if (contract && chainId === ETH_CHAIN_ID) {
        const date = toSeconds(Date.now());
        const monthFromStart = await contract.monthFromStart(date);
        const rate = await contract.holdTimeOptionsForMonth(monthFromStart);
        setOptions(rate.options);
      }

      const contractDSCP = getDSCPContract(provider);
      if (contractDSCP && chainId === ETH_CHAIN_ID && account) {
        const amount = await contractDSCP.balanceOf(account);
        setMaxAmount(fromBNValue(amount.toString()));
      }
    };
    updateRatio();
  }, [account, provider, chainId]);

  const onSwap = async () => {
    if (amount === "") return;

    const amountValue = new Big(amount);
    if (!library || !amountValue) return;
    const contract = getBridgeContract(library.getSigner());
    const contractDSCP = getDSCPContract(library.getSigner());
    const value = toBNValue(amount);
    const bridgeAddress = getEthAddress(addressEth.bridge);
    if (contract && contractDSCP && account && bridgeAddress) {
      const date = toSeconds(Date.now());
      const monthFromStart = await contract.monthFromStart(date);
      const rate = await (
        await contract.holdTimeOptionsForMonth(monthFromStart)
      ).options;
      const actualRate = options[holdValue];
      const monthIndex = rate.findIndex((el) => actualRate === el);
      if (monthIndex >= 0) {
        const tx = await contract.convert(monthIndex, value);
        const transaction: Transaction = createTransaction(
          tx.hash,
          "eth",
          t("transaction_swap", { amount: amountValue.toFixed() }),
          t("transaction_swap_success", { amount: amountValue.toFixed() }),
          t("transaction_swap_failure", { amount: amountValue.toFixed() }),
          chainId
        );
        updateTransactions(transaction);
        await tx.wait();
        const newAllowance = fromBNValue(
          await (
            await contractDSCP.allowance(account, bridgeAddress)
          ).toString()
        );
        const amount = await contractDSCP.balanceOf(account);
        setMaxAmount(fromBNValue(amount.toString()));
        setAllowance(newAllowance);
      }
    }
  };

  const approve = async () => {
    const amountValue = new Big(amount);

    if (!library || !account) return;
    const bridgeAddress = getEthAddress(addressEth.bridge);
    const contractDSCP = getDSCPContract(library.getSigner());
    if (!amountValue || !bridgeAddress || !contractDSCP) return;
    const tx = await contractDSCP.approve(
      bridgeAddress,
      toBNValue(amountValue)
    );
    const transaction: Transaction = createTransaction(
      tx.hash,
      "eth",
      t("transaction_approve", { amount: amountValue.toFixed() }),
      t("transaction_approve_success", { amount: amountValue.toFixed() }),
      t("transaction_approve_failure", { amount: amountValue.toFixed() }),
      chainId
    );
    updateTransactions(transaction);
    await tx.wait();
    const newAllowance = fromBNValue(
      await (await contractDSCP.allowance(account, bridgeAddress)).toString()
    );

    setAllowance(newAllowance);
  };

  return (
    <Wrapper>
      <Header />
      <RightPicture src={background_picture} alt="background_picture" />
      <LeftPicture src={background_picture} alt="background_picture" />
      <ModalContainer>
        <SwapHeader>
          <Button
            isActive={currentTab === SWAP}
            onClick={() => history.push(SWAP)}
          >
            {t("swap_title")}
          </Button>
          <Button
            isActive={currentTab === WITHDRAW}
            onClick={() => history.push(WITHDRAW)}
          >
            {t("withdraw_title")}
          </Button>
        </SwapHeader>
        <ComponentContainer>
          <Switch>
            <Route exact path={SWAP}>
              <Swap
                amount={amount}
                setAmount={setAmount}
                holdValue={holdValue}
                setHoldValue={setHoldValue}
                rateByMonth={formatPercentage(options)}
              />
            </Route>
            <Route path={WITHDRAW}>
              <Withdraw list={list} setList={setList} />
            </Route>
            <Redirect to={SWAP} />
          </Switch>
        </ComponentContainer>

        {location.pathname === SWAP && (
          <SwapButtons
            onSwap={onSwap}
            onApprove={approve}
            amount={amount}
            allowance={allowance}
            maxAmount={maxAmount}
          />
        )}
        <SwapAlert>{t("swap_button_alert")}</SwapAlert>
        <InfoContainer
          href="https://dscpl.medium.com/dear-disciplina-owners-fc018fc21971?postPublishedType=initial"
          target="_blank"
        >
          {t("swap_info")}
          <img src={Medium} width={32} alt="medium" />
        </InfoContainer>
      </ModalContainer>
      <h1>{t("about_title")}</h1>
      <AboutBlockText id="about"> {t("about_info")}</AboutBlockText>
    </Wrapper>
  );
}
