import { providers, Signer } from "ethers";

import { addressEth, addressBsc } from "../config/addresses";
import { Bridge__factory } from "../abi/typings/factories/Bridge__factory";
import { DSCP__factory } from "../abi/typings/factories/DSCP__factory";
import { Disciplina__factory } from "../abi/typings/factories/Disciplina__factory";
import { DSCPL__factory } from "../abi/typings/factories/DSCPL__factory";

export const ETH_CHAIN_ID = 1;
export const BSC_CHAIN_ID = 56;

export type AddressEth = {
  1: string;
  3: string;
};

export type AddressBsc = {
  56: string;
  97: string;
};

export const getEthAddress = (address: AddressEth): string | undefined => {
  const mainNetChainId = 1;
  const chainId = ETH_CHAIN_ID;
  return address[chainId] ? address[chainId] : address[mainNetChainId];
};

export const getBscAddress = (address: AddressBsc): string | undefined => {
  const mainNetChainId = 56;
  const chainId = BSC_CHAIN_ID;
  return address[chainId] ? address[chainId] : address[mainNetChainId];
};

export const getBridgeContract = (
  provider: providers.Provider | Signer | null
) => {
  const address = getEthAddress(addressEth.bridge);
  if (provider && address) {
    return Bridge__factory.connect(address, provider);
  }
};

export const getDSCPContract = (
  provider: providers.Provider | Signer | null
) => {
  const address = getEthAddress(addressEth.dscp);
  if (provider && address) {
    return DSCP__factory.connect(address, provider);
  }
};

export const getDisciplinaContract = (
  provider: providers.Provider | Signer | null
) => {
  const address = getBscAddress(addressBsc.disciplina);
  if (provider && address) {
    return Disciplina__factory.connect(address, provider);
  }
};

export const getDSCPLContract = (
  provider: providers.Provider | Signer | null
) => {
  const address = getBscAddress(addressBsc.dscpl);
  if (provider && address) {
    return DSCPL__factory.connect(address, provider);
  }
};
