import { Wrapper, Title, MainBlock, ContactContainer, Divider } from "./styles";
import twitter from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import telegramChat from "../../assets/images/tg_chat.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import weibo from "../../assets/images/weibo.svg";
import github from "../../assets/images/github.svg";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title id="contacts">{t("contacts_title")}</Title>
      <ContactContainer>
        <MainBlock>
          <p>{t("contacts_email")}</p>
          <p>{t("contacts_address")}</p>
        </MainBlock>
        <Divider />

        <MainBlock>
          <div>
            <a href="https://t.me/disciplinachain">
              <img src={telegram} alt="" />
            </a>
            <a href="https://t.me/disciplinaofficial">
              <img src={telegramChat} alt="" />
            </a>
            <a href="https://www.facebook.com/tchmpls.events/">
              <img src={facebook} alt="" />
            </a>
            <a href="https://github.com/DisciplinaOU">
              <img src={github} alt="" />
            </a>
            <a href="https://twitter.com/tchmpls_events">
              <img src={twitter} alt="" />
            </a>
            <a href="https://www.instagram.com/disciplina.io/">
              <img src={instagram} alt="" />
            </a>
            <a href="https://weibo.com/u/7575569948">
              <img src={weibo} alt="" />
            </a>
          </div>

          <a href="https://blog.disciplina.io" target="_blank" rel="noreferrer">
            blog.disciplina.io
          </a>
        </MainBlock>
      </ContactContainer>
    </Wrapper>
  );
}
