import styled from "styled-components";

const BonusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  & > h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.buttonBackground};
    margin-block-start: 0;
    margin-block-end: 0;
  }
  & > p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.buttonBackground};
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;
const HoldTimeItem = styled.div`
  background: ${({ theme }) => theme.colors.buttonBackground};
  border-radius: 2px;
  padding: 5px;
  margin: 0 20px;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  @media all and (max-width: 700px) {
    margin: 0 10px;
  }
`;
const HoldTimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
  align-items: center;
  > div {
    flex: 1;
    font-size: 0.625rem;
    text-align: center;
    position: relative;
  }
`;
const HoldTimeInput = styled.input`
  width: 90%;
  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    margin-top: -7px;
  }
  &:focus {
    outline: none;
  }
  &::-moz-range-thumb {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.sliderGradient};
    border-color: transparent;
    color: transparent;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background: ${({ theme }) => theme.colors.sliderGradient};
    cursor: pointer;
    border-radius: 5px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px;
    background: ${({ theme }) => theme.colors.sliderGradient};
    cursor: pointer;
    border-radius: 5px;
  }
`;
const SwapInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  @media all and (max-width: 700px) {
    flex-direction: column;
    align-items: baseline;
    padding: 0;
  }
`;

const SwapRatio = styled.div`
  & > h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.defaultYellow};
    margin-block-start: 0;
    margin-block-end: 0;
  }
  & > p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.defaultYellow};
    margin-block-start: 0;
    margin-block-end: 0;
  }
  @media all and (max-width: 700px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const SwapInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.defaultYellow};
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0 10px;
  }
  @media all and (max-width: 700px) {
    width: 100%;
  }
`;
const Rate = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.defaultYellow};
  border-radius: 7px;
  width: 131px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  & > p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.625rem;
    line-height: 21px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  & span {
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 21px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const GateInfo = styled.div`
  font-size: 0.87rem;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.1rem;
  font-weight: 400;
  font-family: "Roboto";
`;

const RecommendedValue = styled.div`
  color: ${({ theme }) => theme.colors.greenCheckPoint};
  font-weight: bold;
  font-family: "Roboto";
  position: absolute;
  top: -15px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.71rem;
`;

export {
  Rate,
  BonusContainer,
  HoldTimeInput,
  HoldTimeInputContainer,
  HoldTimeItem,
  SwapInfoContainer,
  SwapRatio,
  SwapInfoWrapper,
  GateInfo,
  RecommendedValue,
};
