import Big from "big.js";

export const PRECISION = 18;
export const BASE = 10;

export const toBNValue = (value: string | Big): string => {
  return new Big(value).mul(new Big(BASE).pow(PRECISION)).toFixed();
};

export const fromBNValue = (value: string): Big => {
  return new Big(value).div(new Big(BASE).pow(PRECISION));
};

export const formatPercentage = (arr: number[]): number[] => {
  return arr.map((el) => (el !== 0 ? el / 100 : el));
};

export const transformAddress = (address: string) => {
  return `${address.substring(0, 6)}…${address.substring(address.length - 4)}`;
};
