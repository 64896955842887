import moment from "moment";
import { useTranslation } from "react-i18next";

const getRatio = (
  currentDate: number
): { ratio: number; holdTimeOptions: number[]; month: number } => {
  for (let i = 0; i < defaultRatio.length; i++) {
    if (currentDate < defaultRatio[i].time) {
      return {
        ratio: defaultRatio[i].ratio,
        holdTimeOptions: defaultRatio[i].holdTimeOptions,
        month: defaultRatio[i].month,
      };
    }
  }

  return {
    ratio: defaultRatio[defaultRatio.length - 1].ratio,
    holdTimeOptions: defaultRatio[defaultRatio.length - 1].holdTimeOptions,
    month: defaultRatio[defaultRatio.length - 1].month,
  };
};

//INFO: we use default ratios in case the device don't have metamask and possibility to make call to blockchain
const defaultRatio = [
  {
    time: 1621814400000,
    ratio: 10,
    holdTimeOptions: [0, 500, 700, 1200, 2500, 6000],
    month: 4,
  }, // Before 24st May 2021
  {
    time: 1624492800000,
    ratio: 9,
    holdTimeOptions: [0, 400, 500, 1000, 2000, 5000],
    month: 5,
  }, // Before 24st June 2021
  {
    time: 1627084800000,
    ratio: 8,
    holdTimeOptions: [0, 300, 400, 900, 1800, 4500],
    month: 6,
  }, // Before 24st July 2021
  {
    time: 1629763200000,
    ratio: 7,
    holdTimeOptions: [0, 200, 400, 800, 1600, 4000],
    month: 7,
  }, // Before 24st August 2021
  {
    time: 1632441600000,
    ratio: 6,
    holdTimeOptions: [0, 100, 300, 600, 1400, 3500],
    month: 8,
  }, // Before 24st September 2021
  {
    time: 1635033600000,
    ratio: 5,
    holdTimeOptions: [0, 100, 200, 500, 1200, 3000],
    month: 9,
  }, // Before 24st October 2021
  {
    time: 1637712000000,
    ratio: 4,
    holdTimeOptions: [0, 100, 100, 400, 1100, 2500],
    month: 10,
  }, // Before 24st November 2021
  {
    time: 1640304000000,
    ratio: 3,
    holdTimeOptions: [0, 100, 100, 300, 1000, 2000],
    month: 11,
  }, // Before 24st December 2021
  {
    time: 1642982400000,
    ratio: 2,
    holdTimeOptions: [0, 100, 100, 200, 900, 1500],
    month: 0,
  }, // Before 24st January 2022
  {
    time: 1645660800000,
    ratio: 1,
    holdTimeOptions: [0, 100, 100, 100, 800, 1000],
    month: 1,
  },
];

export default function useRatio() {
  const currentDate = moment().valueOf();

  const { t } = useTranslation();
  const { ratio, holdTimeOptions, month } = getRatio(currentDate);

  const months = [
    t("swap_january"),
    t("swap_february"),
    t("swap_march"),
    t("swap_april"),
    t("swap_may"),
    t("swap_june"),
    t("swap_july"),
    t("swap_august"),
    t("swap_september"),
    t("swap_october"),
    t("swap_november"),
    t("swap_december"),
  ];

  return {
    ratio: ratio,
    month: months[month],
    rateByMonth: holdTimeOptions,
  };
}
