import close_icon from "../../assets/images/close_icon.svg";
import styled from "styled-components";
import twitter from "../../assets/images/twitter.svg";
import telegram from "../../assets/images/telegram.svg";
import telegramChat from "../../assets/images/tg_chat.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import weibo from "../../assets/images/weibo.svg";
import github from "../../assets/images/github.svg";
import { useTranslation } from "react-i18next";

const Wrap = styled.div`
  position: absolute;
  width: 569px;
  background: ${({ theme }) => theme.colors.buttonDisabled};
  border-radius: 10px;
  z-index: 2;
  @media all and (max-width: 500px) {
    width: 300px;
  }
`;

const Button = styled.button`
  color: ${({ theme }) => theme.colors.buttonActiveText};
  box-sizing: border-box;
  border: none !important;
  cursor: pointer;
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: auto;
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  margin: 3rem;
`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.modalBg};
  position: fixed;
  top: 0px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0;
  padding: 10px;
  line-height: 30px;
  vertical-align: top;
  font-weight: 300;
  font-size: 1.25rem;
  & > p {
    margin: 0;
  }
  & > div > a {
    margin: 10px;
  }
  & > a {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
    padding: 0px 8px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.875rem;
    margin-left 10px;
  }
  @media all and (max-width: 600px) {
    display: flex;
    align-items: center;
    text-align: center;
    & > a {
      margin-top:10px;
      margin-left 0;
    }
  }
`;

type ModalProps = {
  close: () => void;
  open: boolean;
};

export const Modals = ({ close, open }: ModalProps) => {
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <>
      <ModalBackground onClick={close}>
        <Wrap
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box>
            <Button type="button" onClick={(e) => close()}>
              <img src={close_icon} alt="close_icon" />
            </Button>
            <TextContainer>{t("withdraw_modal_info")} </TextContainer>
            <MainBlock>
              <div>
                <a
                  href="https://t.me/disciplinachain"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={telegram} alt="" />
                </a>
                <a
                  href="https://t.me/disciplinaofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={telegramChat} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/tchmpls.events/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="" />
                </a>
                <a
                  href="https://github.com/DisciplinaOU"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github} alt="" />
                </a>
                <a
                  href="https://twitter.com/tchmpls_events"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/disciplina.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="" />
                </a>
                <a
                  href="https://weibo.com/u/7575569948"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={weibo} alt="" />
                </a>
              </div>

              <a
                href="mailto:hello@disciplina.io"
                target="_blank"
                rel="noreferrer"
              >
                hello@disciplina.io
              </a>
            </MainBlock>
          </Box>
        </Wrap>
      </ModalBackground>
    </>
  );
};
