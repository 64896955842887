import styled from "styled-components";

const Select = styled.div`
  position: relative;
  display: flex;
  width: 5em;
  height: 3em;
  line-height: 3;
  overflow: hidden;
  border-radius: 0.25em;
  background: transparent;
  margin-left: 30px;
  &:after {
    content: "\\25BE";
    position: absolute;
    top: -4px;
    right: 0;
    padding: 0 1em;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
  &:hover::after {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const SelectRoot = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: transparent;

  flex: 1;
  padding: 0 0.5em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-image: none;
  &:option {
    color: ${({ theme }) => theme.colors.black};
  }
  :-ms-expand {
    display: none;
  }
  @media all and (max-width: 750px) {
    font-size: 1.25rem;
  }
`;

const Divider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.colors.white};
  height: 13px;
  margin: auto;
`;

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  align-items: center;
  & > nav {
    display: flex;
    justify-content: space-between;
  }

  @media all and (max-width: 500px) {
    & > img {
      width: 65%;
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
  @media all and (max-width: 750px) {
    display: none;
  }
`;

export { Select, SelectRoot, Divider, HeaderBlock, NavLinks };
