import styled from "styled-components";

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.turquoise},
    ${({ theme }) => theme.colors.blue}
  );
  width: 100%;

  margin: 0 auto;
  text-align: center;
  font-family: "Helvetica Neue";
  font-weight: 300;
  position: relative;
  overflow: hidden;
  & > h1 {
  padding: 2% 5%;
  overflow: hidden;
  position:relative;
  & > h1{
    font-size: 1.875rem;
    line-height: 35px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
  }
  & > p {
    font-size: 1rem;
    line-height: 18px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
  }
 
`;

const ModalContainer = styled.div`
  max-width: 620px;
  margin: auto;
  z-index: 10;
  position: relative;
  padding: 29px 44px;
  box-sizing: border-box;
  text-align: left;
  @media all and (max-width: 500px) {
    margin: 5px 0;
    padding: 10px 5px;
  }
`;

const ComponentContainer = styled.div`
  background: ${({ theme }) => theme.colors.blueModal};
  padding: 29px 44px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media all and (max-width: 500px) {
    margin: 5px 0;
    padding: 10px 15px;
  }
`;

const SwapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  & > h1 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const LeftPicture = styled.img`
  position: absolute;
  width: 1147px;
  height: 591px;
  left: -501px;
  top: 160px;
`;

const RightPicture = styled.img`
  position: absolute;
  width: 1147px;
  height: 591px;
  left: 900px;
  top: 600px;
`;

const AboutBlockText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 18px;
  font-weight: 300;
  padding: 0 13% 2%;
`;

const InfoContainer = styled.a`
  cursor: pointer;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  & > img {
    margin-left: 10px;
  }
  &:visited,
  &:link,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Button = styled.button<{ isActive: boolean }>`
  width: calc(50% - 7px);
  outline: none;
  font-family: Roboto;
  border-radius: 10px;
  padding: 17px 0px;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 28px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.buttonBackground : "transparent"};
  border: 2px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.buttonBackground : theme.colors.white};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.buttonActiveText : theme.colors.white};
`;

const SwapAlert = styled.div`
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 31px 27px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  z-index: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 620px;

  @media all and (max-width: 500px) {
    margin: 20px 0;
    font-size: 25px;
    padding: 15px 10px;
    line-height: 25px;
  }
`;

export {
  Wrapper,
  ModalContainer,
  SwapHeader,
  RightPicture,
  LeftPicture,
  AboutBlockText,
  InfoContainer,
  ComponentContainer,
  Button,
  SwapAlert,
};
