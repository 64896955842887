import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import useRatio from "../../hooks/useRatio";
import { CustomInput, EndIconType } from "../../components/input";
import {
  BonusContainer,
  HoldTimeInput,
  HoldTimeInputContainer,
  HoldTimeItem,
  SwapInfoContainer,
  SwapRatio,
  SwapInfoWrapper,
  Rate,
  GateInfo,
  RecommendedValue,
} from "./styles";
import { ThemeContext } from "styled-components";

export const holdTimeDays = [0, 31, 90, 180, 365, 730];
export const RECOMMENDED_INDEX = 2;

export default function Swap({
  amount,
  setAmount,
  holdValue,
  setHoldValue,
  rateByMonth,
}: {
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
  holdValue: number;
  setHoldValue: (arg0: number) => void;
  rateByMonth: number[];
}) {
  const { ratio } = useRatio();
  const { t } = useTranslation();

  const bonus = Number(
    Number(amount) *
      ratio *
      (1 + (rateByMonth ? Number(rateByMonth[holdValue]) / 100 : 0))
  ).toFixed(4); // (DSCP * Ratio) + bonus

  return (
    <>
      <div>
        <h3>{t("swap_hold_time")}</h3>
        <div>
          <HoldTimeInputContainer>
            {holdTimeDays.map((el, index) => (
              <div key={index}>
                {index === RECOMMENDED_INDEX && (
                  <RecommendedValue>{t("swap_recommended")}</RecommendedValue>
                )}
                {el} {t("swap_days")}
              </div>
            ))}
          </HoldTimeInputContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <HoldTimeInput
              type="range"
              value={holdValue}
              min="0"
              max="5"
              step="1"
              onChange={(e) => setHoldValue(Number(e.target.value))}
            />
          </div>
          <HoldTimeInputContainer>
            {rateByMonth &&
              rateByMonth.map((el, index) => (
                <HoldTimeItem key={index}>{el}%</HoldTimeItem>
              ))}
          </HoldTimeInputContainer>
        </div>
        <BonusContainer>
          <h3>{t("swap_bonus")}</h3>
          <p>{t("swap_bonus_info")}</p>
        </BonusContainer>
      </div>
      <CustomInput
        value={amount}
        onValueChange={setAmount}
        label={t("swap_amount")}
        endIcon={EndIconType.DSCP}
      />
      <SwapInfo />
      <CustomInput
        value={bonus}
        disabled
        label={t("swap_you_get")}
        endIcon={EndIconType.DSCPL}
      />
      <GateInfo>{t("about_gate_helps")} </GateInfo>
    </>
  );
}

const SwapInfo = () => {
  const { month, ratio } = useRatio();
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  return (
    <SwapInfoWrapper>
      <SwapRatio>
        <h3>{t("swap_swap_coefficient")}</h3>
        <p>{t("swap_depends_on_month")}</p>
      </SwapRatio>
      <SwapInfoContainer>
        <Rate style={{ color: themeContext.colors.defaultYellow }}>
          <p>
            <span>1</span> {t("common_dscp")} = <span>{ratio}</span>{" "}
            {t("common_dscpl")}
          </p>
        </Rate>
        <p>{t("swap_until")}</p>
        <Rate style={{ background: themeContext.colors.defaultYellow }}>
          <p>
            <span>{month}</span>
          </p>
        </Rate>
      </SwapInfoContainer>
    </SwapInfoWrapper>
  );
};
