import PendingIcon from "../../assets/images/PendingIcon.svg";
import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface Props {
  pendingCount: number;
  isActive: boolean;
  onClick?: () => void;
}

const Button = styled.button`
  color: ${({ theme }) => theme.colors.buttonActiveText};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.white};
  max-width: 620px;
  cursor: pointer;
  background-color: transparent;
  width: 135px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  & > span {
    font-weight: bold;
    pointer-events: none;
  }
  &: active {
    opacity: 0.3;
    outline: none;
  }
  &: focus {
    outline: none;
  }

  @media all and (max-width: 500px) {
    margin: 20px 0;
    font-size: 1.5625rem;
    padding: 15px 10px;
    line-height: 25px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Loader = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Span = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

const PendingButton: FC<Props> = ({ pendingCount, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick}>
      <Box>
        <Loader src={PendingIcon} alt="pending-icon" />
        <Span>
          {pendingCount} {t("button_pending")}
        </Span>
      </Box>
    </Button>
  );
};

export default PendingButton;
