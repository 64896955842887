import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import { useEffect } from "react";
import { BlockInfo, useStore, Transaction } from "./store";
import { useRefresh } from "./RefreshService";
import { notify } from "../App";
import {
  BSC_CHAIN_ID,
  getBscAddress,
  getDSCPLContract,
} from "../hooks/useContract";
import { addressBsc } from "../config/addresses";
import { fromBNValue } from "../utils/numberFormatter";
import { useProvider } from "../hooks/useProvider";

export function shouldCheck(
  lastBlockNumber: BlockInfo,
  tx: Transaction
): boolean {
  if (!tx.lastCheckedBlockNumber || !lastBlockNumber.data[tx.chain])
    return true;
  const activeChainIndex = lastBlockNumber.data[tx.chain].findIndex(
    (el: { chainId: number; blockNumber: number }) => el.chainId === tx.chainId
  );

  if (activeChainIndex < 0) return false;

  const blocksSinceCheck =
    lastBlockNumber.data[tx.chain][activeChainIndex].blockNumber -
    tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

export default function TransactionUpdater(): null {
  const { chainId, library } = useWeb3ReactCore<Web3Provider>();
  const {
    transactions,
    blockNumberInfo,
    updateTransactions,
    setAmountDSCPL,
  } = useStore();
  const provider = useProvider();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    async function updateTransactionsStatuses() {
      const activeTransactions: Transaction[] = transactions
        .filter((el: Transaction) => el.status === "pending")
        .filter((el: Transaction) => shouldCheck(blockNumberInfo, el));

      if (activeTransactions.length) {
        activeTransactions.map(async (tx: Transaction) => {
          if (tx.chain === "eth") {
            const activeChainIndex = blockNumberInfo.data.eth.findIndex(
              (el: { chainId: number; blockNumber: number }) =>
                el.chainId === tx.chainId
            );
            if (
              !chainId ||
              !library ||
              !blockNumberInfo.data.eth ||
              activeChainIndex < 0
            )
              return;

            tx.lastCheckedBlockNumber =
              blockNumberInfo.data.eth[activeChainIndex].blockNumber;
            const receipt = await library.getTransactionReceipt(tx.hash);

            if (receipt && tx.status === "pending") {
              tx.receipt = receipt;
              tx.status = receipt.status ? "success" : "failed";
              if (tx.onSuccess && tx.status === "success") {
                notify(tx.onSuccess);
              } else if (tx.onFailure) {
                notify(tx.onFailure);
              }
            }
            updateTransactions(tx);
          }
        });
      }
    }

    const updateDSCPLBalance = async () => {
      if (!provider) return;
      const contract = getDSCPLContract(provider);
      const address = getBscAddress(addressBsc.disciplina);
      if (contract && chainId === BSC_CHAIN_ID && address) {
        const amount = await contract.balanceOf(address);
        setAmountDSCPL(fromBNValue(amount.toString()).toString());
      }
    };

    updateDSCPLBalance();
    updateTransactionsStatuses();
  }, [fastRefresh, chainId, library]);
  return null;
}
