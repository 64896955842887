import { useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { useRefresh } from "./RefreshService";
import { useStore } from "./store";

export default function BlockUpdater(): null {
  const { fastRefresh } = useRefresh();
  const { library, chainId } = useWeb3React<Web3Provider>();
  const { blockNumberInfo, setBlockNumberInfo } = useStore();

  useEffect(() => {
    const newInfo = { ...blockNumberInfo };
    if (library && chainId) {
      library.getBlockNumber().then((blockNumber) => {
        const infoIndex = newInfo.data.eth.findIndex(
          (el: { chainId: number; blockNumber: number }) =>
            el.chainId === chainId
        );
        if (infoIndex < 0) {
          newInfo.data.eth = [...newInfo.data.eth, { chainId, blockNumber }];
        } else {
          newInfo.data.eth[infoIndex] = { chainId, blockNumber };
        }
      });
    }
    console.log(newInfo);
    setBlockNumberInfo(newInfo);
  }, [fastRefresh, chainId, library]);
  return null;
}
