import styled from "styled-components";

const InputContainer = styled.div`
  border: none;
  height: 100px;
  margin-bottom: 20px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  padding-right: 14px;
  @media all and (max-width: 500px) {
    height: 70px;
  }
`;
const Input = styled.input`
  flex: 1 1 auto;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  overflow: hidden;
  position: relative;
  font-size: 3rem;
  font-weight: 400;
  white-space: nowrap;
  padding-left: 14px;
  text-overflow: ellipsis;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  line-height: 56px;
  :: placeholder {
    color: ${({ theme }) => theme.colors.buttonActiveText};
  }
  @media all and (max-width: 500px) {
    font-size: 1.875rem;
  }
`;

const Label = styled.span`
  margin-left: 7px;
  color: ${({ theme }) => theme.colors.buttonActiveText};
  height: 100%;
  display: flex;
  font-size: 2.1875rem;
  align-items: center;
  font-weight: 400;
  user-select: none;
  border-radius: 0.6rem;
  @media all and (max-width: 500px) {
    font-size: 1.5625rem;
  }
`;

const InputLabel = styled.div`
  margin: 20px 0 10px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 21px;
`;

export { Label, Input, InputContainer, InputLabel };
