import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { BSC_CHAIN_ID, ETH_CHAIN_ID } from "./useContract";

export const injected = new InjectedConnector({
  supportedChainIds: [ETH_CHAIN_ID, BSC_CHAIN_ID],
});

export function useEagerConnect() {
  const { activate, active, library } = useWeb3React();
  const { ethereum } = window as any;
  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        if (ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      }
    });
  }, [activate, active, library, ethereum]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}

export const useProvider = (): providers.Provider | null => {
  const [provider, setProvider] = useState<providers.Provider | null>(null);
  const context = useWeb3React<Web3Provider>();
  const { library } = context;

  useEffect(() => {
    const tryConnect = async () => {
      try {
        if (library && library.provider) {
          try {
            const newProvider = new ethers.providers.Web3Provider(
              library.provider,
              "any"
            );
            newProvider.on("network", (newNetwork, oldNetwork) => {
              if (oldNetwork) {
                window.location.reload();
              }
            });
            setProvider(newProvider);
          } catch (e) {
            console.log(e);
          }
        } else {
          const { ethereum } = window as any;
          if (ethereum) {
            if (ethereum.enable) {
              ethereum.request({ method: "eth_requestAccounts" });
            }
            try {
              const provider = new ethers.providers.Web3Provider(
                ethereum,
                "any"
              );
              provider.on("network", (newNetwork, oldNetwork) => {
                if (oldNetwork) {
                  window.location.reload();
                }
              });
              setProvider(provider);
            } catch (e) {
              console.log(e);
            }
          }
        }
      } catch (e) {
        console.log("error while connecting provider");
      }
    };
    if (!provider) {
      tryConnect();
    }
  }, [provider, library]);
  return provider;
};
